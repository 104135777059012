import * as types from './types';
import { Reducer } from 'redux';

import { initialStore } from '../initial-store';
import { GLOBAL_INTERFACE } from '../../models/initial-store';

export const global: Reducer<GLOBAL_INTERFACE> = (state = initialStore.global, { payload, type }) => {
	switch (type) {
		case types.SET_GLOBAL_COLLAPSED: {
			return { ...state, collapsed: payload.collapsed };
		}
		default:
			return state;
	}
};
