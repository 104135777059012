/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';

import createSagaMiddleware from 'redux-saga';

import { initialStore } from './initial-store';
import { rootReducer } from './global-reducer';
import rootSaga from '../sagas';

const composeEnhancers =
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && process.env.NODE_ENV !== 'production'
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: compose;

const sagaMiddleware = createSagaMiddleware();

let middlewares: any = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
	const logger = createLogger({
		collapsed: true,
		predicate: (getState, action) => !action.type.includes('@@redux-form'),
	});
	middlewares = [...middlewares, logger];
}

export default createStore(rootReducer, initialStore, composeEnhancers(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);
