import { lazy } from 'react';

import { URLS } from '../constants/URLS';
import { ADMIN_ROLE, AVAILABLE_ROLES, SELLER_ROLE } from '../constants';

// TODO wnen you add new route, it should use lazy from React
export const ROUTES = [
	{
		id: 1,
		path: URLS.landing,
		exact: true,
		layoutComponent: false,
		hidden: false,
		hasAccess: [],
		component: lazy(() => import('./landing')),
	},
	{
		id: 2,
		path: URLS.signIn,
		exact: true,
		layoutComponent: false,
		hidden: false,
		hasAccess: [],
		component: lazy(() => import('./sign-in-page')),
	},
	{
		id: 3,
		path: URLS.dashboard,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: AVAILABLE_ROLES,
		component: lazy(() => import('./dashboard')),
	},
	{
		id: 4,
		path: URLS.users,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./users-page')),
	},
	{
		id: 5,
		path: URLS.profile,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: AVAILABLE_ROLES,
		component: lazy(() => import('./profile')),
	},
	{
		id: 6,
		path: URLS.members,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./members-page')),
	},
	{
		id: 7,
		path: URLS.sale,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: [SELLER_ROLE, ADMIN_ROLE],
		component: lazy(() => import('./sale')),
	},
	{
		id: 8,
		path: URLS.portfolio,
		exact: true,
		layoutComponent: true,
		hidden: false,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./portfolio')),
	},
	{
		id: 9,
		path: URLS.portfolioCreate,
		exact: true,
		layoutComponent: true,
		hidden: false,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./portfolio-item')),
	},
	{
		id: 10,
		path: `${URLS.portfolioUpdate}/:id`,
		exact: true,
		layoutComponent: true,
		hidden: false,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./portfolio-item')),
	},
	{
		id: 11,
		path: URLS.restorePass,
		exact: true,
		layoutComponent: false,
		hidden: false,
		hasAccess: [],
		component: lazy(() => import('./restore-password')),
	},
	{
		id: 12,
		path: URLS.bullBoard,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./bull-board')),
	},
	{
		id: 13,
		path: URLS.htmlEditor,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./html-editor')),
	},
	{
		id: 14,
		path: URLS.htmlEditorCreate,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./html-editor/Editor')),
	},
	{
		id: 15,
		path: `${URLS.htmlEditorUpdate}/:id`,
		exact: true,
		layoutComponent: true,
		hidden: true,
		hasAccess: [ADMIN_ROLE],
		component: lazy(() => import('./html-editor/Editor')),
	},
	{
		id: 1005001,
		path: URLS.error,
		exact: true,
		layoutComponent: false,
		hidden: false,
		hasAccess: [],
		component: lazy(() => import('./error-page')),
	},
	{
		id: 1005002,
		exact: true,
		layoutComponent: false,
		hidden: false,
		hasAccess: [],
		component: lazy(() => import('./error-page')),
	},
];
