import { fork } from 'redux-saga/effects';

import auth from './auth';
import users from './users';
import members from './members';
import portfolio from './portfolio';
import sales from './sales';
import services from './services';

export default function*() {
	yield fork(auth);
	yield fork(users);
	yield fork(members);
	yield fork(sales);
	yield fork(portfolio);
	yield fork(services);
}
