import { setTheme } from './localstage-actions';

export const generalStyles = Object.freeze({
	'--base-white': '#fff',
	'--base-black': '#000',
	'--base-red': '#e11',

	'--antd-active-background': 'rgb(35, 144, 255)',
	'--icon-colors': 'rgba(0, 0, 0, 0.25)',

	'--disabled-button-color': 'rgba(0, 0, 0, 0.25)',
	'--disabled-button-background-color': '#f5f5f5',
	'--disabled-button-border-color': '#d9d9d9',
});

type options = {
	[key: string]: string;
};

export const lightTheme: options = Object.freeze({
	'--background-color': '#fff',
	'--text-color': '#000',
	'--ant-menu-color': '#fff',
	'--border-card-color': '#fff',
	'--table-border-color': '#dee2e6',
	'--profile-color-custom-input': '#fcfcfc',
	'--profile-color-ant-select-dropdown-menu-item': '#0074f0',
	'--layout-color-logo': '#4b545c',
	'--layout-color-menu-item': '#c2c7d0',
	'--anticon-color': '#000',
	'--anticon-color-hover': '#17a2b8',
	'--anticon-delete-color-hover': '#dc3545',
	'--header-color-link': '#16181b',
	'--header-background-color-link': '#f8f9fa',
	'--header-border-color-side-dropdown-menu': 'rgba(0,0,0,.15)',
	'--text-color-app-version': '#fff',
	'--border-color': '#808080',
	'--box-shadow-color': '#808080',
	'--active-link-color': 'rgb(35, 144, 255)',
	'--theme-color': '#1890ff',
	'--card-border-color': '#d9d9d9',
	'--input-text-background': '#fff',
	'--background-table-cell': '#F5F7FC',
	'--input-text-color': 'rgba(0, 0, 0, 0.65)',
	'--input-box-shadow': '0 0 0 2px rgba(24, 144, 255, 0.2)',
	'--background-color-layout': '#F5F7FC',
	'--background-color-header': '#fff',
	'--active-menu-item': 'rgba(255,255,255,.9)',
	'--active-hover-item': 'rgba(255,255,255,.1)',
	'--background-color-sidebar': '#343a40',
	'--background-color-footer-border': '#343a40',
	'--background-color-footer': '#e9e6e6',
});

export const darkTheme: options = Object.freeze({
	'--background-color': 'rgb(2, 21, 41)',
	'--text-color': '#bfc1c4',
	'--border-color': '#bfc1c4',
	'--active-link-color': '#f26b6b',
	'--theme-color': '#e06565',
	'--card-border-color': '#bfc1c4',
	'--input-text-color': '#bfc1c4',
	'--input-text-background': '#2d3035',
	'--background-table-cell': '#282b2f',
	'--input-box-shadow': '0 0 0 2px rgba(224, 101, 101, 0.2)',
	'--background-color-layout': '#22252a',
	'--background-color-header': '#2d3035',
	'--active-menu-item': '#34373d',

	// will continue to change
	'--table-border-color': '#dee2e6',
	'--profile-color-custom-input': '#fcfcfc',
	'--profile-color-ant-select-dropdown-menu-item': '#0074f0',
	'--layout-color-logo': '#4b545c',
	'--layout-color-menu-item': '#c2c7d0',
	'--anticon-color': '#000',
	'--anticon-color-hover': '#17a2b8',
	'--anticon-delete-color-hover': '#dc3545',
	'--header-color-link': '#16181b',
	'--header-background-color-link': '#f8f9fa',
	'--header-border-color-side-dropdown-menu': 'rgba(0,0,0,.15)',
	'--text-color-app-version': '#fff',
	'--border-card-color': '#fff',
	'--ant-menu-color': '#000',
	'--background-color-sidebar': '#343a40',
	'--active-hover-item': 'rgba(255,255,255,.1)',
	'--box-shadow-color': '#808080',
	'--background-color-footer-border': '#343a40',
	'--background-color-footer': '#e9e6e6',
});

export const DEFAULT_THEME = true;

export const AVAILABLE_THEMES = [
	{ target: 'light', value: true },
	{ target: 'dark', value: false },
];

type THEMES_TYPE = {
	[key: string]: {
		[key: string]: string;
	};
};

const THEMES: THEMES_TYPE = {
	light: lightTheme,
	dark: darkTheme,
};

export const SET_CSS_PROPERTY = (value: boolean) => {
	const theme = AVAILABLE_THEMES.find(el => el.value === value);
	if (!theme) return false;

	setTheme(theme.target);
	Object.keys(THEMES[theme.target]).forEach(el =>
		document.documentElement.style.setProperty(el, THEMES[theme.target][el]),
	);
};
