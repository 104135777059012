export const CREATE_PORTFOLIO_WORK = 'portfolio/CREATE_PORTFOLIO_WORK';
export const CREATE_PORTFOLIO_WORK_SUCCESS = 'portfolio/CREATE_PORTFOLIO_WORK_SUCCESS';
export const CREATE_PORTFOLIO_WORK_FAILURE = 'portfolio/CREATE_PORTFOLIO_WORK_FAILURE';

export const UPDATE_PORTFOLIO_WORK = 'portfolio/UPDATE_PORTFOLIO_WORK';
export const UPDATE_PORTFOLIO_WORK_SUCCESS = 'portfolio/UPDATE_PORTFOLIO_WORK_SUCCESS';
export const UPDATE_PORTFOLIO_WORK_FAILURE = 'portfolio/UPDATE_PORTFOLIO_WORK_FAILURE';

export const DELETE_PORTFOLIO_WORK = 'portfolio/DELETE_PORTFOLIO_WORK';
export const DELETE_PORTFOLIO_WORK_SUCCESS = 'portfolio/DELETE_PORTFOLIO_WORK_SUCCESS';
export const DELETE_PORTFOLIO_WORK_FAILURE = 'portfolio/DELETE_PORTFOLIO_WORK_FAILURE';

export const GET_PORTFOLIO = 'portfolio/GET_PORTFOLIO';
export const GET_PORTFOLIO_SUCCESS = 'portfolio/GET_PORTFOLIO_SUCCESS';
export const GET_PORTFOLIO_FAILURE = 'portfolio/GET_PORTFOLIO_FAILURE';

export const GET_PORTFOLIO_BY_ID = 'portfolio/GET_PORTFOLIO_BY_ID';
export const GET_PORTFOLIO_BY_ID_SUCCESS = 'portfolio/GET_PORTFOLIO_BY_ID_SUCCESS';
export const GET_PORTFOLIO_BY_ID_FAILURE = 'portfolio/GET_PORTFOLIO_BY_ID_FAILURE';

export const RESET_PORTFOLIO = 'portfolio/RESET_PORTFOLIO';
