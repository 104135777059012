export const CREATE_SERVICES = 'services/CREATE_SERVICES';
export const CREATE_SERVICES_SUCCESS = 'services/CREATE_SERVICES_SUCCESS';
export const CREATE_SERVICES_FAILURE = 'services/CREATE_SERVICES_FAILURE';

export const UPDATE_SERVICES = 'services/UPDATE_SERVICES';
export const UPDATE_SERVICES_SUCCESS = 'services/UPDATE_SERVICES_SUCCESS';
export const UPDATE_SERVICES_FAILURE = 'services/UPDATE_SERVICES_FAILURE';

export const DELETE_SERVICES = 'services/DELETE_SERVICES';
export const DELETE_SERVICES_SUCCESS = 'services/DELETE_SERVICES_SUCCESS';
export const DELETE_SERVICES_FAILURE = 'services/DELETE_SERVICES_FAILURE';

export const GET_SERVICES = 'services/GET_SERVICES';
export const GET_SERVICES_SUCCESS = 'services/GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAILURE = 'services/GET_SERVICES_FAILURE';

export const GET_SERVICES_BY_ID = 'services/GET_SERVICES_BY_ID';
export const GET_SERVICES_BY_ID_SUCCESS = 'services/GET_SERVICES_BY_ID_SUCCESS';
export const GET_SERVICES_BY_ID_FAILURE = 'services/GET_SERVICES_BY_ID_FAILURE';

export const RESET_SERVICES = 'services/RESET_SERVICES';
