import { Reducer } from 'redux';
import * as types from './types';

import { initialStore } from '../initial-store';
import { PASSWORD_RESTORE_SCHEMA } from '../../models/initial-store';

export const passwordRestore: Reducer<PASSWORD_RESTORE_SCHEMA> = (
	state = initialStore.passwordRestore,
	{ payload, type },
) => {
	switch (type) {
		case types.PASSWORD_RESTORE: {
			return { ...state, isFetching: true, error: null, success: false };
		}
		case types.PASSWORD_RESTORE_SUCCESS: {
			return { ...state, isFetching: false, error: null, success: true };
		}
		case types.PASSWORD_RESTORE_FAIL: {
			return { ...state, isFetching: false, error: payload };
		}
		case types.VALIDATE_RESTORE_TOKEN: {
			return { ...state, isFetching: true, error: null, token: null };
		}
		case types.VALIDATE_RESTORE_TOKEN_SUCCESS: {
			return { ...state, isFetching: false, error: null, token: payload.data.token };
		}
		case types.VALIDATE_RESTORE_TOKEN_FAIL: {
			return { ...state, isFetching: false, error: payload };
		}
		default:
			return state;
	}
};
