import axios, { AxiosRequestConfig } from 'axios';
import { openAxiosNotificationFail } from './utils/show-notification';
import { getToken } from './utils/localstage-actions';

const instance = {
	baseURL: process.env.REACT_APP_BASE_API_URL,
	timeout: 10000,
	headers: {
		'content-type': 'application/json',
	},
};

export const AxiosInstance = axios.create(instance);

AxiosInstance.interceptors.request.use(
	config => {
		// check for Authorization property
		if (config.headers.Authorization) return config;

		const token = getToken();
		return {
			...config,
			headers: { ...config.headers, Authorization: `Bearer ${token}` },
		};
	},
	error => Promise.reject(error),
);

AxiosInstance.interceptors.response.use(
	res => res,
	error => {
		if (error.response.status !== 414) {
			openAxiosNotificationFail(error);
		}
		return Promise.reject(error.response ? error : { ...error, response: 'Some internal error occured' });
	},
);

export const http = async ({ url, method = 'GET', params, data, ...rest }: AxiosRequestConfig) => {
	return await AxiosInstance({ url, method, params, data, ...rest });
};
