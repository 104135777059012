import { call, takeEvery, put } from 'redux-saga/effects';
import { API_URLS } from '../../constants/API_URLS';
import * as types from '../../store/sales/types';

// ACTIONS
import * as salesActions from '../../store/sales/action';

// MODELS
import {
	getListOfUsersAction,
	getAdvertsResponse,
	addCommentFormAction,
	getCommentResponse,
	bullAction,
} from '../../models/sales';

// UTILS
import { http } from '../../axios';
import { openNotificationSuccess } from '../../utils/show-notification';

function* getAdvert({ payload }: getListOfUsersAction) {
	try {
		const response: getAdvertsResponse = yield call(http, {
			url: API_URLS.craigslist,
			params: payload,
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
			},
		});

		yield put(salesActions.getAdvertsSuccess(response.data));
	} catch (error) {
		yield put(salesActions.getAdvertsFail(error.response));
	}
}

function* addComment({ payload }: addCommentFormAction) {
	const { id, comment, viewed } = payload;

	try {
		const response: getCommentResponse = yield call(http, {
			url: API_URLS.craigslist + '/' + id,
			method: 'PUT',
			data: { comment, viewed },
		});

		yield put(salesActions.addCommentFormSuccess(response.data.sale));
		yield openNotificationSuccess({ message: response.data.messagePopUp });
	} catch (error) {
		yield put(salesActions.addCommentFormFail(error.response));
	}
}

function* bull({ payload }: bullAction) {
	try {
		yield call(http, {
			url: API_URLS.bullRoute,
			method: 'POST',
			data: payload,
		});
	} catch (error) {
		yield put(salesActions.getAdvertsFail(error));
	}
}

function* sales() {
	yield takeEvery(types.GET_ADVERTS, getAdvert);
	yield takeEvery(types.ADD_COMMENT, addComment);
	yield takeEvery(types.START_BULL, bull);
}

export default sales;
