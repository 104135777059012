import { call, takeEvery, put } from 'redux-saga/effects';
import { API_URLS } from '../../constants/API_URLS';
import { Trim } from '../../i18n';

// MODELS
import {
	CreatePortfolioItemAction,
	UpdatePortfolioItemAction,
	DeletePortfolioItemAction,
	GetPortfolioAction,
	GetPortfolioItemResponse,
	CreatePortfolioItemResponse,
	UpdatePortfolioItemResponse,
	GetPortfolioByIdAction,
	GetPortfolioByIdResponse,
} from '../../models/portfolio';

// ACTION
import * as ACTION_TYPES from '../../store/portfolio/types';

// ACTION CREATORS
import * as ACTION_CREATORS from '../../store/portfolio/actions';

// UTILS
import { http } from '../../axios';
import { openNotificationSuccess } from '../../utils/show-notification';

function* getPortfolioItemer({ payload }: GetPortfolioAction) {
	try {
		const response: GetPortfolioItemResponse = yield call(http, {
			url: API_URLS.portfolio,
			params: payload,
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
			},
		});
		yield put(ACTION_CREATORS.getPortfolioSuccess(response.data));
	} catch (error) {
		yield put(ACTION_CREATORS.getPortfolioFailure(error.response));
	}
}

function* createPortfolioItemWorker({ payload }: CreatePortfolioItemAction) {
	const trimData = Trim(payload);

	try {
		const response: CreatePortfolioItemResponse = yield call(http, {
			url: API_URLS.portfolio,
			method: 'POST',
			data: trimData,
		});
		yield openNotificationSuccess({ message: response.data.messagePopUp });
		yield put(ACTION_CREATORS.createPortfolioItemSuccess(response.data.portfolio));
	} catch (error) {
		yield put(ACTION_CREATORS.createPortfolioItemFailure(error.response));
	}
}

function* updatePortfolioItemWorker({ payload }: UpdatePortfolioItemAction) {
	const trimData = Trim(payload);

	try {
		const response: UpdatePortfolioItemResponse = yield call(http, {
			url: `${API_URLS.portfolio}/${payload.id}`,
			method: 'PUT',
			data: trimData,
		});
		yield openNotificationSuccess({ message: response.data.messagePopUp });
		yield put(ACTION_CREATORS.updatePortfolioItemSuccess(response.data.portfolio));
	} catch (error) {
		yield put(ACTION_CREATORS.updatePortfolioItemFailure(error.response));
	}
}

function* deletePortfolioItemWorker({ payload }: DeletePortfolioItemAction) {
	try {
		const response = yield call(http, {
			url: `${API_URLS.portfolio}/${payload.id}`,
			method: 'DELETE',
		});
		yield openNotificationSuccess({ message: response.data.messagePopUp });
		yield put(ACTION_CREATORS.deletePortfolioItemSuccess(payload));
	} catch (error) {
		yield put(ACTION_CREATORS.deletePortfolioItemFailure(error.response));
	}
}

function* getPortfolioById({ payload }: GetPortfolioByIdAction) {
	try {
		const { data }: GetPortfolioByIdResponse = yield call(http, {
			url: `${API_URLS.portfolio}/${payload}`,
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
			},
		});

		yield put(ACTION_CREATORS.getPortfolioByIdSuccess(data));
	} catch (error) {
		yield put(ACTION_CREATORS.getPortfolioByIdFailure(error.response));
	}
}

function* portfolio() {
	yield takeEvery(ACTION_TYPES.CREATE_PORTFOLIO_WORK, createPortfolioItemWorker);
	yield takeEvery(ACTION_TYPES.GET_PORTFOLIO, getPortfolioItemer);
	yield takeEvery(ACTION_TYPES.DELETE_PORTFOLIO_WORK, deletePortfolioItemWorker);
	yield takeEvery(ACTION_TYPES.UPDATE_PORTFOLIO_WORK, updatePortfolioItemWorker);
	yield takeEvery(ACTION_TYPES.GET_PORTFOLIO_BY_ID, getPortfolioById);
}

export default portfolio;
