import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { URLS } from '../../constants/URLS';
import { checkJWT } from '../../utils/check-jwt';
import { Footer } from '../../components/footer';

import { CustomLayout } from '..';

type PRIVATEROUTE = {
	component: any;
	exact?: boolean;
	path: string | undefined;
	layoutComponent: boolean;
	hidden?: boolean;
	hasAccess: string[];
	innerWidth: number;
};

export const PrivateRoute = ({
	component: Component,
	exact = true,
	path,
	layoutComponent,
	hidden,
	hasAccess,
	innerWidth,
}: PRIVATEROUTE) => {
	return (
		<Route
			exact={exact}
			path={path}
			render={props => {
				const [userId, role] = checkJWT();

				if (hidden && !userId) return <Redirect to={URLS.signIn} />;

				if (hasAccess.length !== 0 && !hasAccess.some(el => el === role)) return <Redirect to={URLS.signIn} />;

				if (!layoutComponent)
					return (
						<Suspense fallback={null}>
							<Component {...props} />
						</Suspense>
					);

				return (
					<CustomLayout {...props}>
						<Suspense fallback={null}>
							<Component {...props} />
						</Suspense>
						{innerWidth <= 600 ? <Footer position={'static'} /> : undefined}
					</CustomLayout>
				);
			}}
		/>
	);
};
