import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

// ACTIONS
import { getCurrentUser } from '../../store/users/action';

// MODELS
import { STORE } from '../../models/initial-store';
import { getCurrentUserType } from '../../models/users';
import { I18n } from '../../models/i18n';
import { CURRENT_USER } from '../../models/users';

// CONSTANTS
import { ROUTES } from '../index';

// COMPONENTS
import { Footer } from '../../components/footer';
import { PrivateRoute } from '../../components/private-route';

// UTILS
import { getToken } from '../../utils/localstage-actions';
import { socket } from '../../utils/socketConnect';
import { DEFAULT_THEME, SET_CSS_PROPERTY, AVAILABLE_THEMES } from '../../utils/css-property';
import { getTheme } from '../../utils/localstage-actions';
import { chooseLanguage, DEFAULT_LANGUAGE } from '../../i18n';

// CONTEXT
import { GeneralAppContext } from '../../context';

interface OwnProps {}

interface StoreProps {
	user: CURRENT_USER;
}

interface DispatchProps {
	getCurrentUser: getCurrentUserType;
}

type AppInterface = StoreProps & DispatchProps & OwnProps;

type AppState = {
	innerWidth: number;
	json: I18n;
	theme: boolean;
};

class App extends Component<AppInterface, AppState> {
	constructor(props: AppInterface) {
		super(props);
		// first initialaze for css
		const themeFromLocalstorage = getTheme();
		const hasAvailableTheme = AVAILABLE_THEMES.find(el => el.target === themeFromLocalstorage);
		const theme = hasAvailableTheme ? hasAvailableTheme.value : DEFAULT_THEME;
		SET_CSS_PROPERTY(theme);

		this.state = {
			innerWidth: window.innerWidth,
			json: chooseLanguage(DEFAULT_LANGUAGE.value),
			theme,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateWidth);

		const { getCurrentUser } = this.props;
		const token = getToken();

		if (token) getCurrentUser();
	}

	componentDidUpdate(prevProps: AppInterface) {
		const { user } = this.props;

		if (user.language !== prevProps.user.language) this.chooselanguage(user.language);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWidth);
	}

	updateWidth = () => this.setState({ innerWidth: window.innerWidth });

	chooselanguage = (newLang: string) => this.setState({ json: chooseLanguage(newLang) });

	changeTheme = (value: boolean) => {
		SET_CSS_PROPERTY(value);
		this.setState({ theme: value });
	};

	getFooter = () => {
		const { innerWidth } = this.state;
		if (window.location.pathname === '/sign-in') return <Footer position={'fixed'} />;
		if (innerWidth > 600) return <Footer position={'fixed'} />;
		return undefined;
	};

	render() {
		const { innerWidth, json, theme } = this.state;
		return (
			<GeneralAppContext.Provider
				value={{
					innerWidth,
					setLanguage: this.chooselanguage,
					json,
					theme,
					setTheme: this.changeTheme,
					socket: socket,
				}}
			>
				<Switch>
					{ROUTES.map(el => (
						<PrivateRoute
							key={el.id}
							exact={el.exact}
							path={el.path}
							component={el.component}
							layoutComponent={el.layoutComponent}
							hidden={el.hidden}
							hasAccess={el.hasAccess}
							innerWidth={innerWidth}
						/>
					))}
				</Switch>
				{this.getFooter()}
			</GeneralAppContext.Provider>
		);
	}
}

const mapStateToProps = (state: STORE) => ({
	user: state.users.currentUser,
});

export default connect<StoreProps, DispatchProps, OwnProps, STORE>(mapStateToProps, { getCurrentUser })(App);
