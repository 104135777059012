import * as types from './types';
import { AxiosError } from 'axios';

// MODELS
import { PasswordReset } from '../../models/auth';

export const passwordReset: PasswordReset = payload => ({
	type: types.PASSWORD_RESET,
	payload,
});

export const passwordResetSuccess = () => ({
	type: types.PASSWORD_RESET_SUCCESS,
});

export const passwordResetFail = (payload: AxiosError) => ({
	type: types.PASSWORD_RESET_FAIL,
	payload,
});
