import { AxiosError } from 'axios';
import * as ACTION_TYPES from './types';

import {
	GetServicesActionCreator,
	GetServicesSuccessActionCreator,
	GetServicesFailureActionCreator,
	DeleteServicesItemActionCreator,
	DeleteServicesItemSuccessActionCreator,
	DeleteServicesItemFailureActionCreator,
	ServicesItem,
} from '../../models/services';

// CREATE
export const createServicesItem = (payload: ServicesItem) => ({
	type: ACTION_TYPES.CREATE_SERVICES,
	payload,
});

export const createServicesItemSuccess = (payload: ServicesItem) => ({
	type: ACTION_TYPES.CREATE_SERVICES_SUCCESS,
	payload,
});

export const createServicesItemFailure = (payload: AxiosError) => ({
	type: ACTION_TYPES.CREATE_SERVICES_FAILURE,
	payload,
});

// READ
export const getServices: GetServicesActionCreator = () => ({
	type: ACTION_TYPES.GET_SERVICES,
});
export const getServicesSuccess: GetServicesSuccessActionCreator = payload => ({
	type: ACTION_TYPES.GET_SERVICES_SUCCESS,
	payload,
});
export const getServicesFailure: GetServicesFailureActionCreator = payload => ({
	type: ACTION_TYPES.GET_SERVICES_FAILURE,
	payload,
});

// UPDATE
export const updateServicesItem = (payload: ServicesItem) => ({
	type: ACTION_TYPES.UPDATE_SERVICES,
	payload,
});

export const updateServicesItemSuccess = (payload: ServicesItem) => ({
	type: ACTION_TYPES.UPDATE_SERVICES_SUCCESS,
	payload,
});

export const updateServicesItemFailure = (payload: AxiosError) => ({
	type: ACTION_TYPES.UPDATE_SERVICES_FAILURE,
	payload,
});

// DELETE
export const deleteServicesItem: DeleteServicesItemActionCreator = payload => ({
	type: ACTION_TYPES.DELETE_SERVICES,
	payload,
});
export const deleteServicesItemSuccess: DeleteServicesItemSuccessActionCreator = payload => ({
	type: ACTION_TYPES.DELETE_SERVICES_SUCCESS,
	payload,
});
export const deleteServicesItemFailure: DeleteServicesItemFailureActionCreator = payload => ({
	type: ACTION_TYPES.DELETE_SERVICES_FAILURE,
	payload,
});

// GET BY ID
export const getServicesById = (payload: string) => ({
	type: ACTION_TYPES.GET_SERVICES_BY_ID,
	payload,
});

export const getServicesByIdSuccess = (payload: ServicesItem) => ({
	type: ACTION_TYPES.GET_SERVICES_BY_ID_SUCCESS,
	payload,
});

export const getServicesByIdFailure = (payload: AxiosError) => ({
	type: ACTION_TYPES.GET_SERVICES_BY_ID_FAILURE,
	payload,
});

export const resetServices = () => ({
	type: ACTION_TYPES.RESET_SERVICES,
});
