import { call, takeEvery, put } from 'redux-saga/effects';
import { API_URLS } from '../../constants/API_URLS';
import * as types from '../../store/users/types';
import { Trim } from '../../i18n';

// ACTIONS
import * as userActions from '../../store/users/action';

// MODELS
import {
	getCurrentUserResponse,
	getListOfUsersResponse,
	getListOfUsersAction,
	createUserAction,
	createUserTypeResponse,
	updateUserAction,
	updateUserTypeResponse,
	сhangeUserPasswordAction,
	removeUserAction,
	сhangeUserEmailAction,
	setDefaultUserPasswordAction,
} from '../../models/users';

// UTILS
import { http } from '../../axios';
import { openNotificationSuccess } from '../../utils/show-notification';

function* getUser() {
	try {
		const response: getCurrentUserResponse = yield call(http, {
			url: API_URLS.currentUser,
		});

		yield put(userActions.getCurrentUserSuccess(response.data));
	} catch (error) {
		yield put(userActions.getCurrentUserFail(error.response));
	}
}

function* getListOfUsers({ payload }: getListOfUsersAction) {
	try {
		const response: getListOfUsersResponse = yield call(http, {
			url: API_URLS.users,
			params: payload,
		});

		yield put(userActions.getListOfUsersSuccess(response.data));
	} catch (error) {
		yield put(userActions.getListOfUsersFail(error));
	}
}

function* createUser({ payload }: createUserAction) {
	const trimData = Trim(payload.values);

	try {
		const response: createUserTypeResponse = yield call(http, {
			url: API_URLS.users,
			method: 'POST',
			data: trimData,
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
			},
		});
		yield put(userActions.createUserSuccess(response.data.user));
		yield openNotificationSuccess({ message: response.data.messagePopUp });
	} catch (error) {
		yield put(userActions.createUserFail(error));
	}
}

function* updateUser({ payload }: updateUserAction) {
	const trimData = Trim(payload.values);

	try {
		const response: updateUserTypeResponse = yield call(http, {
			url: `${API_URLS.users}/${payload.values.id}`,
			method: 'PUT',
			data: trimData,
		});
		yield put(userActions.updateUserSuccess(response.data.user));
		yield openNotificationSuccess({ message: response.data.message || '' });
	} catch (error) {
		yield put(userActions.updateUserFail(error));
	}
}

function* changeUserPassword({ payload }: сhangeUserPasswordAction) {
	const { id, newString, previous, email } = payload;
	try {
		const encodedData = yield window.btoa(`${email}:${previous}`);

		const response = yield call(http, {
			url: API_URLS.updatePassword(id),
			method: 'PUT',
			data: { password: newString },
			headers: {
				Authorization: `Basic ${encodedData}`,
			},
		});

		yield put(userActions.changeUserPasswordSuccess());
		yield openNotificationSuccess({ message: response.data.messagePopUp });
	} catch (error) {
		yield put(userActions.changeUserPasswordFail(error));
	}
}

function* setDefaultUserPassword({ payload }: setDefaultUserPasswordAction) {
	const { id, email } = payload;
	try {
		const response = yield call(http, {
			url: `${API_URLS.users}/${API_URLS.setDefaultPassword}${id}`,
			method: 'PUT',
			data: { email },
		});

		yield put(userActions.changeUserPasswordSuccess());
		yield openNotificationSuccess({ message: response.data.messagePopUp });
	} catch (error) {
		yield put(userActions.changeUserPasswordFail(error));
	}
}

function* changeEmail({ payload }: сhangeUserEmailAction) {
	try {
		const response = yield call(http, {
			url: API_URLS.findEmail,
			params: payload,
		});
		yield put(userActions.changeEmailSuccess({ email: payload.email, existing: response.data.existing }));
	} catch (error) {
		yield put(userActions.getListOfUsersFail(error));
	}
}

function* removeUser({ payload }: removeUserAction) {
	const { id } = payload;
	try {
		const response = yield call(http, {
			url: `${API_URLS.users}/${id}`,
			method: 'DELETE',
		});

		yield put(userActions.removeUserSuccess(id));
		yield openNotificationSuccess({ message: response.data.messagePopUp });
	} catch (error) {
		yield put(userActions.removeUserFail(error));
	}
}

function* users() {
	yield takeEvery(types.GET_CURRENT_USER, getUser);
	yield takeEvery(types.GET_LIST_OF_USERS, getListOfUsers);
	yield takeEvery(types.CHANGE_EMAIL, changeEmail);
	yield takeEvery(types.SET_DEFAULT_USER_PASSWORD, setDefaultUserPassword);
	yield takeEvery(types.CREATE_USER, createUser);
	yield takeEvery(types.UPDATE_USER, updateUser);
	yield takeEvery(types.CHANGE_USER_PASSWORD, changeUserPassword);
	yield takeEvery(types.REMOVE_USER, removeUser);
}

export default users;
