// DEPENDENCIES
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Dropdown } from 'antd';

// MODELS
import { STORE } from '../../models/initial-store';
import { globalStoreResetType } from '../../models/global';
import { CURRENT_USER } from '../../models/users';

// CONSTANTS
import { AVAILABLE_ROLES } from '../../constants';
import { URLS } from '../../constants/URLS';

// ACTION CREATORS
import { globalStoreReset } from '../../store/global/actions';

// UTILS
import { filterProtected, AbstractRoute } from '../../utils/filter-protected';
import { removeToken, getToken, removeTheme } from '../../utils/localstage-actions';

// COMPONENTS
import { CustomAvatar } from '../../components';

// CONTEXT
import { GeneralAppContext } from '../../context';

// STYLES
import './styles.scss';

interface OwnProps {}

interface StoreProps {
	user: CURRENT_USER;
}

interface DispatchProps {
	globalStoreReset: globalStoreResetType;
}

type DesktopHeaderProps = OwnProps & StoreProps & DispatchProps & RouteComponentProps;

class PureDesktopHeader extends Component<DesktopHeaderProps> {
	static contextType = GeneralAppContext;
	context!: React.ContextType<typeof GeneralAppContext>;

	performLogOut = () => {
		const { history, globalStoreReset } = this.props;
		removeToken();
		removeTheme();
		globalStoreReset();

		history.push(URLS.signIn);
	};

	// componentDidUpdate(prevProps: DesktopHeaderProps) {
	// 	const { user } = this.props;
	// 	const ctx = this.context;
	// 	console.log('p === user', prevProps.user.language);
	// 	console.log('user', user.language);
	// 	if (user.language !== prevProps.user.language) ctx.setLanguage(user.language);
	// }

	sideMenu = () => {
		const { history } = this.props;
		const { json } = this.context;
		const { HEADER } = json;
		return (
			<Menu className="header__side-dropdown-menu">
				{filterProtected({
					routesSchema: [
						{
							title: HEADER.profileLinkTitle,
							path: URLS.profile,
							hasAccess: AVAILABLE_ROLES,
							external: false,
						},
					],
					renderCallback: ({ title, path }: AbstractRoute) => (
						<Menu.Item key={`${path}${title}`} className="header__link" onClick={() => history.push(path)}>
							<span>{title}</span>
						</Menu.Item>
					),
				})}
				<Menu.Divider />
				{getToken() && (
					<Menu.Item key="logout" className="header__link" onClick={this.performLogOut}>
						<span>{HEADER.logOutButtonTitle}</span>
					</Menu.Item>
				)}
			</Menu>
		);
	};

	render() {
		const { user } = this.props;
		const { json } = this.context;
		const { HEADER } = json;
		return (
			<div className="header">
				<div className="header__links-list header__links-list--side">
					<p className="header__user-email">{`${HEADER.hi}, ${user.name}`}</p>
					<Dropdown overlay={this.sideMenu} trigger={['click', 'click']}>
						<CustomAvatar className="header__avatar" src={user.picture} size={40} />
					</Dropdown>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: STORE) => ({
	user: state.users.currentUser,
});

export const CustomHeader = connect<StoreProps, DispatchProps, OwnProps, STORE>(mapStateToProps, { globalStoreReset })(
	PureDesktopHeader,
);
