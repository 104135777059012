import React from 'react';

import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

import './styles.scss';

export interface SpinnerProps extends SpinProps {
	isVisible: boolean;
	type?: 'adjusted' | 'common';
}

export const Spinner = ({ isVisible, type = 'common', ...props }: SpinnerProps) => {
	if (!isVisible) return null;

	return (
		<div className={`spinner__wrapper spinner__wrapper--${type}`}>
			<Spin {...props} />
		</div>
	);
};
