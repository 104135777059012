import { call, takeEvery, put } from 'redux-saga/effects';
import { API_URLS } from '../../constants/API_URLS';
import * as types from '../../store/members/types';
import { Trim } from '../../i18n';

import { DEFAULT_MEMBERS_SORT_VALUE, DEFAULT_PAGE_LIMIT_MEMBERS_LIST } from '../../constants';
// ACTIONS
import * as memberActions from '../../store/members/action';

// MODELS
import {
	createMemberAction,
	deleteMemberAction,
	deleteMemberSuccessResponse,
	getListOfMembersAction,
	getListOfMembersResponse,
	updateMemberAction,
	updateMemberOrderAction,
	updateMemberSuccessResponse,
} from '../../models/members';

// UTILS
import { http } from '../../axios';
import { openNotificationSuccess } from '../../utils/show-notification';

function* getListOfMembers({ payload }: getListOfMembersAction) {
	try {
		const response: getListOfMembersResponse = yield call(http, {
			url: API_URLS.members,
			method: 'GET',
			params: payload,
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
			},
		});
		yield put(memberActions.getListOfMembersSuccess(response.data));
	} catch (error) {
		yield put(memberActions.getListOfMembersFail(error));
	}
}

function* createMember({ payload }: createMemberAction) {
	const trimData = Trim(payload);

	try {
		const response = yield call(http, {
			url: API_URLS.members,
			method: 'POST',
			data: trimData,
		});

		yield put(memberActions.createMemberSuccess(payload));
		yield openNotificationSuccess({ message: response.data.messagePopUp });
		yield put(
			memberActions.getListOfMembers({
				page: payload.page,
				limit: DEFAULT_PAGE_LIMIT_MEMBERS_LIST,
				sort: DEFAULT_MEMBERS_SORT_VALUE,
			}),
		);
	} catch (error) {
		yield put(memberActions.createMemberFail(error));
	}
}

function* updateMember({ payload }: updateMemberAction) {
	const trimData = Trim(payload);

	try {
		const response: updateMemberSuccessResponse = yield call(http, {
			url: API_URLS.members + '/' + payload.id,
			method: 'PUT',
			data: trimData,
		});

		yield put(memberActions.updateMemberSuccess(response.data));
		yield openNotificationSuccess({ message: response.data.messagePopUp || '' });
		yield put(
			memberActions.getListOfMembers({
				page: payload.page,
				limit: DEFAULT_PAGE_LIMIT_MEMBERS_LIST,
				sort: DEFAULT_MEMBERS_SORT_VALUE,
			}),
		);
	} catch (error) {
		yield put(memberActions.updateMemberFail(error));
	}
}

function* updateMemberOrder({ payload }: updateMemberOrderAction) {
	try {
		const response: updateMemberSuccessResponse = yield call(http, {
			url: API_URLS.members + '/' + payload.id + '/order',
			method: 'PUT',
			data: payload,
		});
		const { messagePopUp, ...data } = response.data;

		yield put(memberActions.updateMemberOrderSuccess(data));
		yield openNotificationSuccess({ message: messagePopUp ? messagePopUp : '' });
	} catch (error) {
		yield put(memberActions.updateMemberFail(error));
	}
}

function* deleteMember({ payload }: deleteMemberAction) {
	try {
		const response: deleteMemberSuccessResponse = yield call(http, {
			url: API_URLS.members + '/' + payload.id,
			method: 'DELETE',
		});

		yield put(memberActions.deleteMemberSuccess(response.data));
		yield openNotificationSuccess({ message: response.data.messagePopUp ? response.data.messagePopUp : '' });
		yield put(
			memberActions.getListOfMembers({
				limit: DEFAULT_PAGE_LIMIT_MEMBERS_LIST,
				page: payload.page,
				sort: DEFAULT_MEMBERS_SORT_VALUE,
			}),
		);
	} catch (error) {
		yield put(memberActions.deletedMemberFail(error));
	}
}

function* members() {
	yield takeEvery(types.GET_LIST_OF_MEMBERS, getListOfMembers);
	yield takeEvery(types.CREATE_MEMBER, createMember);
	yield takeEvery(types.UPDATE_MEMBER, updateMember);
	yield takeEvery(types.DELETE_MEMBER, deleteMember);
	yield takeEvery(types.UPDATE_MEMBER_ORDER, updateMemberOrder);
}

export default members;
