import React from 'react';

// DEFAULT LOGO IMAGE
import defaultLogo from '../../assets/twsgo_logo.png';

type LogoProps = {
	className?: string;
	logo?: string;
	style?: React.CSSProperties;
};

export const Logo = ({ className, logo = defaultLogo, style }: LogoProps) => {
	return (
		<div className={`company-logo ${className}`}>
			<img className="company-logo__image" src={logo} style={style} alt="company logo" />
		</div>
	);
};
