import React, { PureComponent } from 'react';
import { Modal } from 'antd';

import './style.scss';

type CustomModalProps = {
	closeEvent: () => void;
	visible: boolean;
	width?: number | string;
	className?: string;
	children: React.ReactNode;
	footer?: React.ReactNode;
	title: string;
};

export class CustomModal extends PureComponent<CustomModalProps> {
	static defaultProps = {
		width: 400,
		footer: null,
		title: '',
	};

	render() {
		const { children, width, className, closeEvent, visible, footer, title } = this.props;

		return (
			<Modal
				width={width}
				wrapClassName={className}
				title={title}
				visible={visible}
				onCancel={closeEvent}
				footer={footer}
			>
				{children}
			</Modal>
		);
	}
}
