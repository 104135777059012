import { Reducer } from 'redux';
import * as types from './types';

import { initialStore } from '../initial-store';
import { MEMBER_INTERFACE } from '../../models/initial-store';
import { SEARCH_MEMBER_FORM } from '../../constants';
import { setLink } from '../../utils/check-absolute-path';
import { CREATE_MEMBER_INITIAL_VALUE } from '../../models/members';

export const members: Reducer<MEMBER_INTERFACE> = (state = initialStore.members, { payload, type, meta }) => {
	switch (type) {
		case types.GET_LIST_OF_MEMBERS: {
			return { ...state, isFetching: true };
		}
		case types.GET_LIST_OF_MEMBERS_SUCCESS: {
			const { rows, count } = payload;
			const listOfMembers = rows.map((el: CREATE_MEMBER_INITIAL_VALUE) => ({ ...el, image: setLink(el.image) }));
			return { ...state, isFetching: false, count, listOfMembers };
		}
		case types.GET_LIST_OF_MEMBERS_FAIL: {
			return { ...state, isFetching: false };
		}
		case types.CREATE_MEMBER_SUCCESS: {
			return { ...state, count: state.count + 1 };
		}
		case types.UPDATE_MEMBER_SUCCESS: {
			const { count, member } = payload;

			const listOfMembers = state.listOfMembers.map((el: CREATE_MEMBER_INITIAL_VALUE) =>
				el.id === member.id ? { ...member, image: setLink(el.image) } : { ...el, image: setLink(el.image) },
			);

			return { ...state, count, listOfMembers };
		}
		case types.SELECT_MEMBER: {
			return { ...state, currentMember: payload };
		}
		case types.DELETE_MEMBER: {
			return { ...state };
		}
		case types.DELETE_MEMBER_SUCCESS: {
			return { ...state };
		}
		case types.UPDATE_MEMBER_ORDER_SUCCESS: {
			const { rows, count } = payload;
			const listOfMembers = rows.map((el: CREATE_MEMBER_INITIAL_VALUE) => ({ ...el, image: setLink(el.image) }));
			return { ...state, count, listOfMembers };
		}
		case '@@redux-form/CHANGE': {
			if (meta.form === SEARCH_MEMBER_FORM) {
				return { ...state, searchQueryString: payload };
			}
			return state;
		}
		case '@@redux-form/RESET': {
			if (meta.form === SEARCH_MEMBER_FORM) {
				return { ...state, searchQueryString: '' };
			}
			return state;
		}
		default:
			return state;
	}
};
