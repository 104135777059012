import * as types from './types';
import { AxiosError } from 'axios';

import {
	CURRENT_USER,
	getListOfUsersResponseType,
	getListOfUsersPayload,
	сhangeUserPasswordPayload,
	setInitialValueType,
	createUserType,
	updateUserType,
	removeUserType,
	сhangeUserEmailPayload,
	setDefaultUserPasswordPayload,
	сhangeUserEmailSuccessPayload,
} from '../../models/users';

export const getCurrentUser = () => ({
	type: types.GET_CURRENT_USER,
});

export const changeEmail = (payload: сhangeUserEmailPayload) => ({
	type: types.CHANGE_EMAIL,
	payload,
});

export const changeEmailSuccess = (payload: сhangeUserEmailSuccessPayload) => ({
	type: types.CHANGE_EMAIL_SUCCESS,
	payload,
});

export const getCurrentUserSuccess = (payload: CURRENT_USER) => ({
	type: types.GET_CURRENT_USER_SUCCESS,
	payload,
});

export const getCurrentUserFail = (payload: AxiosError) => ({
	type: types.GET_CURRENT_USER_FAIL,
	payload,
});

export const getListOfUsers = (payload: getListOfUsersPayload) => ({
	type: types.GET_LIST_OF_USERS,
	payload,
});

export const getListOfUsersSuccess = (payload: getListOfUsersResponseType) => ({
	type: types.GET_LIST_OF_USERS_SUCCESS,
	payload,
});

export const getListOfUsersFail = (payload: AxiosError) => ({
	type: types.GET_LIST_OF_USERS_FAIL,
	payload,
});

// create user
export const createUser: createUserType = payload => ({
	type: types.CREATE_USER,
	payload,
});

export const createUserSuccess = (payload: CURRENT_USER) => ({
	type: types.CREATE_USER_SUCCESS,
	payload,
});

export const createUserFail = (payload: AxiosError) => ({
	type: types.CREATE_USER_FAIL,
	payload,
});

// update user
export const updateUser: updateUserType = payload => ({
	type: types.UPDATE_USER,
	payload,
});

export const updateUserSuccess = (payload: CURRENT_USER) => ({
	type: types.UPDATE_USER_SUCCESS,
	payload,
});

export const updateUserFail = (payload: AxiosError) => ({
	type: types.UPDATE_USER_FAIL,
	payload,
});

// change password
export const changeUserPassword = (payload: сhangeUserPasswordPayload) => ({
	type: types.CHANGE_USER_PASSWORD,
	payload,
});

export const changeUserPasswordSuccess = () => ({
	type: types.CHANGE_USER_PASSWORD_SUCCESS,
});

export const changeUserPasswordFail = (payload: AxiosError) => ({
	type: types.CHANGE_USER_PASSWORD_FAIL,
	payload,
});

export const setDefaultUserPassword = (payload: setDefaultUserPasswordPayload) => ({
	type: types.SET_DEFAULT_USER_PASSWORD,
	payload,
});

// change password
export const removeUser: removeUserType = payload => ({
	type: types.REMOVE_USER,
	payload,
});

export const removeUserSuccess = (payload: string) => ({
	type: types.REMOVE_USER_SUCCESS,
	payload,
});

export const removeUserFail = (payload: AxiosError) => ({
	type: types.REMOVE_USER_FAIL,
	payload,
});

// other
export const setInitialValue: setInitialValueType = payload => ({
	type: types.SET_INITIAL_VALUE,
	payload,
});
