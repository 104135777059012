export const API_URLS = {
	auth: 'auth',
	loginOAuth: {
		google: 'auth/google',
		github: 'auth/github',
	},
	currentUser: 'users/me',
	users: 'users',
	findEmail: '/users/findEmail',
	members: 'members',
	setDefaultPassword: 'setDefaultPassword/',
	changeRole: function(id: string): string {
		return `${this.users}/${id}/role`;
	},
	updatePassword: function(id: string): string {
		return `${this.users}/${id}/password`;
	},
	portfolio: 'portfolio',
	craigslist: 'craigslist',
	bullRoute: 'bull',
	services: 'services',
	craigslistViews: 'craigslist/views',
	resetPass: 'password-resets',
};
