import * as types from './types';
import { AxiosError } from 'axios';

import {
	CURRENT_MEMBER,
	getListOfMembersResponseType,
	PAGINATION,
	CREATE_MEMBER_INITIAL_VALUE,
} from '../../models/members';

export const getListOfMembers = (payload: PAGINATION) => ({
	type: types.GET_LIST_OF_MEMBERS,
	payload,
});

export const getListOfMembersSuccess = (payload: getListOfMembersResponseType) => ({
	type: types.GET_LIST_OF_MEMBERS_SUCCESS,
	payload,
});

export const getListOfMembersFail = (payload: AxiosError) => ({
	type: types.GET_LIST_OF_MEMBERS_FAIL,
	payload,
});

export const createMember = (payload: CREATE_MEMBER_INITIAL_VALUE) => ({
	type: types.CREATE_MEMBER,
	payload,
});

export const createMemberSuccess = (payload: CREATE_MEMBER_INITIAL_VALUE) => ({
	type: types.CREATE_MEMBER_SUCCESS,
	payload,
});

export const createMemberFail = (payload: AxiosError) => ({
	type: types.CREATE_MEMBER_FAIL,
	payload,
});

export const updateMember = (payload: CREATE_MEMBER_INITIAL_VALUE) => ({
	type: types.UPDATE_MEMBER,
	payload,
});

export const updateMemberSuccess = (payload: CURRENT_MEMBER) => ({
	type: types.UPDATE_MEMBER_SUCCESS,
	payload,
});

export const updateMemberFail = (payload: AxiosError) => ({
	type: types.UPDATE_MEMBER_FAIL,
	payload,
});

export const deleteMember = (payload: CREATE_MEMBER_INITIAL_VALUE & PAGINATION) => ({
	type: types.DELETE_MEMBER,
	payload,
});

export const deleteMemberSuccess = (payload: CURRENT_MEMBER) => ({
	type: types.DELETE_MEMBER_SUCCESS,
	payload,
});

export const deletedMemberFail = (payload: AxiosError) => ({
	type: types.DELETE_MEMBER_FAIL,
	payload,
});

export const selectMember = (payload: CREATE_MEMBER_INITIAL_VALUE) => ({
	type: types.SELECT_MEMBER,
	payload,
});

export const updateMemberOrder = (payload: CREATE_MEMBER_INITIAL_VALUE) => ({
	type: types.UPDATE_MEMBER_ORDER,
	payload,
});

export const updateMemberOrderSuccess = (payload: CURRENT_MEMBER) => ({
	type: types.UPDATE_MEMBER_ORDER_SUCCESS,
	payload,
});

export const updateMemberOrderFail = (payload: AxiosError) => ({
	type: types.UPDATE_MEMBER_ORDER_FAIL,
	payload,
});
