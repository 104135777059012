export const GET_CURRENT_USER = 'user/GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'user/GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAIL = 'user/GET_CURRENT_USER_FAIL';

export const GET_LIST_OF_USERS = 'user/GET_LIST_OF_USERS';
export const GET_LIST_OF_USERS_SUCCESS = 'user/GET_LIST_OF_USERS_SUCCESS';
export const GET_LIST_OF_USERS_FAIL = 'user/GET_LIST_OF_USERS_FAIL';

export const CREATE_USER = 'user/CREATE_USER';
export const CREATE_USER_SUCCESS = 'user/CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'user/CREATE_USER_FAIL';

export const UPDATE_USER = 'user/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'user/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'user/UPDATE_USER_FAIL';

export const CHANGE_USER_PASSWORD = 'user/CHANGE_USER_PASSWORD';
export const CHANGE_USER_PASSWORD_SUCCESS = 'user/CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAIL = 'user/CHANGE_USER_PASSWORD_FAIL';
export const SET_DEFAULT_USER_PASSWORD = 'user/SET_DEFAULT_USER_PASSWORD';

export const REMOVE_USER = 'user/REMOVE_USER';
export const REMOVE_USER_SUCCESS = 'user/REMOVE_USER_SUCCESS';
export const REMOVE_USER_FAIL = 'user/REMOVE_USER_FAIL';

export const CHANGE_EMAIL = 'user/CHANGE_EMAIL';
export const CHANGE_EMAIL_SUCCESS = 'user/CHANGE_EMAIL_SUCCESS';

export const SET_INITIAL_VALUE = 'user/SET_INITIAL_VALUE';
