import React from 'react';
import { useLocation } from 'react-router-dom';

// import { GeneralAppContext } from '../../context/context';

import './style.scss';

export const Footer = (data: any) => {
	const { position } = data;
	const year = new Date().getFullYear();
	const { pathname } = useLocation();
	if (pathname === '/sign-in')
		return (
			<footer id="sign-in-footer" className="sign-in-footer">
				<div className="container">
					<p>
						<a href={process.env.REACT_APP_STORYBOOK_URL}>Storybook Link</a>
					</p>
					<p>
						<span id="current_year">{year}</span>© Copyright TWS LLC. All Rights Reserved.
					</p>
					<p>Version - {process.env.REACT_APP_VERSION_HASH}</p>
				</div>
			</footer>
		);
	return (
		<footer className="footer" style={{ position: data ? position : 'fixed', color: 'rgba(0, 0, 0, 0.65)' }}>
			<span className="footer__app-version">App version {process.env.REACT_APP_VERSION_HASH}</span>
		</footer>
	);
};
