import React from 'react';
import { Tag } from 'antd';

type TagType = {
	afterClose?: () => void;
	children: React.ReactNode;
	color?: string;
	closable?: boolean;
	onClose?: (e: React.MouseEvent<HTMLOrSVGElement>) => void;
	visible?: boolean;
	className?: string;
};

export const CustomTag = ({ children, className = '', ...rest }: TagType) => {
	return (
		<Tag className={`tag ${className}`} {...rest}>
			{children}
		</Tag>
	);
};
