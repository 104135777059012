import React from 'react';
import { Button } from 'antd';

import './style.scss';

type ButtonType = {
	children: React.ReactNode;
	className?: string;
	htmlType?: 'button' | 'reset' | 'submit' | undefined;
	disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const CustomButton = ({ children, className = '', htmlType = 'button', ...rest }: ButtonType) => {
	return (
		<Button className={`button ${className}`} htmlType={htmlType} {...rest}>
			{children}
		</Button>
	);
};
