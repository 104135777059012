import { Reducer } from 'redux';
import * as ACTION_TYPES from './types';

import { initialStore } from '../initial-store';
import { SERVICES_SCHEMA } from '../../models/initial-store';

export const servicesReducer: Reducer<SERVICES_SCHEMA> = (state = initialStore.services, { payload, type }) => {
	switch (type) {
		case ACTION_TYPES.GET_SERVICES_BY_ID:
		case ACTION_TYPES.CREATE_SERVICES:
		case ACTION_TYPES.GET_SERVICES:
		case ACTION_TYPES.UPDATE_SERVICES:
		case ACTION_TYPES.DELETE_SERVICES: {
			return { ...state, isFetching: true, error: null };
		}

		case ACTION_TYPES.GET_SERVICES_BY_ID_FAILURE:
		case ACTION_TYPES.CREATE_SERVICES_FAILURE:
		case ACTION_TYPES.GET_SERVICES_FAILURE:
		case ACTION_TYPES.UPDATE_SERVICES_FAILURE:
		case ACTION_TYPES.DELETE_SERVICES_FAILURE: {
			return { ...state, isFetching: false, error: payload };
		}

		case ACTION_TYPES.CREATE_SERVICES_SUCCESS: {
			return { ...state, currentPortfolio: payload, isFetching: false, error: null };
		}

		case ACTION_TYPES.GET_SERVICES_SUCCESS: {
			const { rows, count } = payload;
			return { ...state, servicesList: rows, count };
		}

		case ACTION_TYPES.UPDATE_SERVICES_SUCCESS: {
			return { ...state, currentPortfolio: payload, isFetching: false, error: null };
		}

		case ACTION_TYPES.DELETE_SERVICES_SUCCESS: {
			return {
				...state,
				isFetching: false,
				error: null,
				servicesList: state.servicesList.filter((el: { id: any }) => el.id !== payload.id),
			};
		}

		case ACTION_TYPES.GET_SERVICES_BY_ID_SUCCESS: {
			const currentServices = {
				...payload,
				entityMap: payload.entityMap,
				blocks: payload.blocks,
			};
			return { ...state, isFetching: false, currentServices };
		}

		case ACTION_TYPES.RESET_SERVICES: {
			return { ...state, currentServices: initialStore.services.currentServices };
		}

		default:
			return state;
	}
};
