import { AxiosError, AxiosResponse } from 'axios';
import * as types from './types';

// MODELS
import { PasswordRestore, ValidateToken } from '../../models/auth';

export const passwordRestore: PasswordRestore = payload => ({
	type: types.PASSWORD_RESTORE,
	payload,
});

export const passwordRestoreSuccess = (payload: AxiosResponse) => ({
	type: types.PASSWORD_RESTORE_SUCCESS,
	payload,
});

export const passwordRestoreFail = (payload: AxiosError) => ({
	type: types.PASSWORD_RESTORE_FAIL,
	payload,
});

export const validateToken: ValidateToken = payload => ({
	type: types.VALIDATE_RESTORE_TOKEN,
	payload,
});

export const validateTokenSuccess = (payload: AxiosResponse) => ({
	type: types.VALIDATE_RESTORE_TOKEN_SUCCESS,
	payload,
});

export const validateTokenFail = (payload: AxiosError) => ({
	type: types.VALIDATE_RESTORE_TOKEN_FAIL,
	payload,
});
