import React from 'react';
import { Table, Empty } from 'antd';
import { PaginationConfig, PaginationProps } from 'antd/lib/pagination';
import { TableCurrentDataSource, SorterResult } from 'antd/lib/table';
import { I18n } from '../../models/i18n';

type changeTableEvent = (
	pagination: PaginationConfig,
	filters: Record<never, string[]>,
	sorter: SorterResult<{}>,
	extra: TableCurrentDataSource<{}>,
) => void;

interface TableTypes {
	changeTableEvent?: changeTableEvent | undefined;
	data: any;
	columns: any;
	rowKey?: string;
	loading?: boolean;
	pagination?: PaginationProps | false;
	FORMS: I18n['FORMS'];
	scroll?: {
		x?: boolean | number | string;
		y?: boolean | number | string;
	};
	className?: string;
	bordered?: boolean;
}

export const CustomTable = ({
	loading = false,
	changeTableEvent,
	data = undefined,
	columns,
	rowKey = 'id',
	pagination = undefined,
	scroll,
	className,
	FORMS,
	bordered,
}: TableTypes) => {
	return (
		<Table
			locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={FORMS.PLACEHOLDERS.noData} /> }}
			loading={loading}
			onChange={changeTableEvent}
			dataSource={data}
			columns={columns}
			rowKey={rowKey}
			pagination={pagination}
			scroll={scroll}
			className={className}
			bordered={bordered}
		/>
	);
};
