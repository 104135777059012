import * as types from './types';
import { GLOBAL_TYPE } from '../../models/global';

export const globalStoreReset = () => ({
	type: types.GLOBAL_STORE_RESET,
});

export const globalCollapsed = (payload: GLOBAL_TYPE) => ({
	type: types.SET_GLOBAL_COLLAPSED,
	payload,
});
