export const URLS = {
	landing: '/',
	sale: '/sale',
	signIn: '/sign-in',
	error: '/error',
	dashboard: '/dashboard',
	users: '/users',
	profile: '/profile',
	members: '/members',
	portfolio: '/portfolio',
	htmlEditor: '/htmlEditor',
	htmlEditorCreate: '/htmlEditor/create',
	htmlEditorUpdate: '/htmlEditor/update',
	portfolioCreate: '/portfolio/create',
	portfolioUpdate: '/portfolio/update',
	bullBoard: '/bull-board',
	restorePass: '/restore-password/:token',
};
