import { Reducer } from 'redux';
import * as types from './types';

import { initialStore } from '../initial-store';
import { USER_INTERFACE } from '../../models/initial-store';

import { SEARCH_USER_FORM } from '../../constants';

export const users: Reducer<USER_INTERFACE> = (state = initialStore.users, { payload, type, meta }) => {
	switch (type) {
		case types.GET_CURRENT_USER_SUCCESS: {
			return { ...state, currentUser: payload, isAuthorized: true };
		}

		case types.REMOVE_USER:
		case types.CHANGE_EMAIL: {
			return { ...state, detectedEmail: '' };
		}
		case types.CHANGE_EMAIL_SUCCESS: {
			const { email, existing } = payload;
			return { ...state, detectedEmail: existing ? email : '' };
		}

		case types.SET_DEFAULT_USER_PASSWORD:
		case types.CHANGE_USER_PASSWORD:
		case types.UPDATE_USER: {
			return { ...state, isFetching: true };
		}

		case types.GET_LIST_OF_USERS: {
			const { page, limit } = payload;
			return { ...state, isFetching: true, paginationProperty: { page, limit } };
		}

		case types.GET_LIST_OF_USERS_SUCCESS: {
			const { rows, count } = payload;
			return { ...state, isFetching: false, count, listOfUsers: rows };
		}

		case types.CREATE_USER_SUCCESS: {
			if (state.count < state.paginationProperty.limit) {
				return {
					...state,
					count: state.count + 1,
					listOfUsers: [payload, ...state.listOfUsers],
				};
			}

			if (state.paginationProperty.page === 1) {
				return {
					...state,
					count: state.count + 1,
					listOfUsers: [payload, ...state.listOfUsers.slice(0, -1)],
				};
			}
			return { ...state, count: state.count + 1 };
		}

		case types.REMOVE_USER_FAIL:
		case types.CHANGE_USER_PASSWORD_SUCCESS:
		case types.CHANGE_USER_PASSWORD_FAIL:
		case types.UPDATE_USER_FAIL:
		case types.CREATE_USER:
		case types.GET_LIST_OF_USERS_FAIL:
		case types.CREATE_USER_FAIL: {
			return { ...state, isFetching: false };
		}

		case types.SET_INITIAL_VALUE: {
			if (!payload) {
				return { ...state, initialValue: initialStore.users.initialValue };
			}
			const user = state.listOfUsers.find(el => el.id === payload);
			/* eslint-disable @typescript-eslint/indent */
			const initialValue = user
				? {
						role: user.role,
						picture: user.picture,
						email: user.email,
						language: user.language,
						name: user.name,
						id: user.id,
				  }
				: initialStore.users.initialValue;
			return { ...state, initialValue };
		}

		case types.UPDATE_USER_SUCCESS: {
			return {
				...state,
				isFetching: false,
				currentUser: state.currentUser.id === payload.id ? payload : state.currentUser,
				listOfUsers: state.listOfUsers.map(el => (el.id === payload.id ? payload : el)),
			};
		}

		case types.REMOVE_USER_SUCCESS: {
			return {
				...state,
				isFetching: false,
				listOfUsers: state.listOfUsers.filter(el => el.id !== payload),
			};
		}

		case '@@redux-form/CHANGE': {
			if (meta.form === SEARCH_USER_FORM) {
				return { ...state, searchQueryString: payload };
			}
			return state;
		}

		case '@@redux-form/RESET': {
			if (meta.form === SEARCH_USER_FORM) {
				return { ...state, searchQueryString: '' };
			}
			return state;
		}

		default:
			return state;
	}
};
