import { call, takeEvery, put } from 'redux-saga/effects';
import { API_URLS } from '../../constants/API_URLS';

// MODELS
import {
	CreateServicesItemAction,
	UpdateServicesItemAction,
	DeleteServicesItemAction,
	GetServicesItemResponse,
	CreateServicesItemResponse,
	UpdateServicesItemResponse,
	GetServicesByIdAction,
	GetServicesByIdResponse,
} from '../../models/services';

// ACTION
import * as ACTION_TYPES from '../../store/services/types';

// ACTION CREATORS
import * as ACTION_CREATORS from '../../store/services/action';

// UTILS
import { http } from '../../axios';
import { openNotificationSuccess } from '../../utils/show-notification';

function* getServicesItem() {
	try {
		const response: GetServicesItemResponse = yield call(http, {
			url: API_URLS.services,
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
			},
		});
		yield put(ACTION_CREATORS.getServicesSuccess(response.data));
	} catch (error) {
		yield put(ACTION_CREATORS.getServicesFailure(error.response));
	}
}

function* createServiceItemWorker({ payload }: CreateServicesItemAction) {
	const { ...data } = payload;
	try {
		const response: CreateServicesItemResponse = yield call(http, {
			url: API_URLS.services,
			method: 'POST',
			data: data.data,
		});
		yield openNotificationSuccess({ message: response.data.messagePopUp });
		yield put(ACTION_CREATORS.createServicesItemSuccess(response.data.services));
	} catch (error) {
		yield put(ACTION_CREATORS.createServicesItemFailure(error.response));
	}
}

function* updateServiceItemWorker({ payload }: UpdateServicesItemAction) {
	const { id, blocks, entityMap } = payload;
	try {
		const response: UpdateServicesItemResponse = yield call(http, {
			url: `${API_URLS.services}/${id}`,
			method: 'PUT',
			data: { blocks, entityMap },
		});
		yield openNotificationSuccess({ message: response.data.messagePopUp });
		yield put(ACTION_CREATORS.updateServicesItemSuccess(response.data.services));
	} catch (error) {
		yield put(ACTION_CREATORS.updateServicesItemFailure(error.response));
	}
}

function* deleteServiceItemWorker({ payload }: DeleteServicesItemAction) {
	console.log(payload);
	try {
		const response = yield call(http, {
			url: `${API_URLS.services}/${payload.id}`,
			method: 'DELETE',
		});
		yield openNotificationSuccess({ message: response.data.messagePopUp });
		yield put(ACTION_CREATORS.deleteServicesItemSuccess(payload));
	} catch (error) {
		yield put(ACTION_CREATORS.deleteServicesItemFailure(error.response));
	}
}

function* getServiceById({ payload }: GetServicesByIdAction) {
	try {
		const { data }: GetServicesByIdResponse = yield call(http, {
			url: `${API_URLS.services}/${payload}`,
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
			},
		});

		yield put(ACTION_CREATORS.getServicesByIdSuccess(data));
	} catch (error) {
		yield put(ACTION_CREATORS.getServicesByIdFailure(error.response));
	}
}

function* services() {
	yield takeEvery(ACTION_TYPES.CREATE_SERVICES, createServiceItemWorker);
	yield takeEvery(ACTION_TYPES.GET_SERVICES, getServicesItem);
	yield takeEvery(ACTION_TYPES.DELETE_SERVICES, deleteServiceItemWorker);
	yield takeEvery(ACTION_TYPES.UPDATE_SERVICES, updateServiceItemWorker);
	yield takeEvery(ACTION_TYPES.GET_SERVICES_BY_ID, getServiceById);
}

export default services;
