export const LOCALSTORAGE_TOKEN_NAME = 'token';
export const LOCALSTORAGE_THEME_NAME = 'theme';

export const setToken = (token: string) => localStorage.setItem(LOCALSTORAGE_TOKEN_NAME, token);
export const getToken = (): string | null => localStorage.getItem(LOCALSTORAGE_TOKEN_NAME);
export const removeToken = () => localStorage.removeItem(LOCALSTORAGE_TOKEN_NAME);

export const setTheme = (theme: string) => localStorage.setItem(LOCALSTORAGE_THEME_NAME, theme);
export const getTheme = (): string | null => localStorage.getItem(LOCALSTORAGE_THEME_NAME);
export const removeTheme = () => localStorage.removeItem(LOCALSTORAGE_THEME_NAME);
