import { AxiosError } from 'axios';

import * as types from './types';

import {
	getAdvertsTypePayload,
	getAdvertsResponseType,
	addCommentFormPayload,
	ADVERTS_LIST,
	bullTypePayload,
} from '../../models/sales';

export const getAdverts = (payload: getAdvertsTypePayload) => ({
	type: types.GET_ADVERTS,
	payload,
});

export const getAdvertsSuccess = (payload: getAdvertsResponseType) => ({
	type: types.GET_ADVERTS_SUCCESS,
	payload,
});

export const getAdvertsFail = (payload: AxiosError) => ({
	type: types.GET_ADVERTS_FAIL,
	payload,
});

export const addCommentForm = (payload: addCommentFormPayload) => ({
	type: types.ADD_COMMENT,
	payload,
});

export const addCommentFormSuccess = (payload: ADVERTS_LIST) => ({
	type: types.ADD_COMMENT_SUCCESS,
	payload,
});

export const addCommentFormFail = (payload: AxiosError) => ({
	type: types.ADD_COMMENT_FAIL,
	payload,
});

export const startBull = (payload: bullTypePayload) => ({
	type: types.START_BULL,
	payload,
});
