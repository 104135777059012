import en from '../i18n/en.json';
import ru from '../i18n/ru.json';

import { I18n } from '../models/i18n';

export const AVAILABLE_LANGUAGES = [
	{ json: en, title: 'English', value: 'en' },
	{ json: ru, title: 'Русский', value: 'ru' },
];

export const chooseLanguage = (lang: string): I18n => {
	const hasLang = AVAILABLE_LANGUAGES.find(el => el.value === lang);
	return hasLang ? hasLang.json : en;
};

export const Trim = (obj: any) => {
	const objTrim: any = {};

	for (const prop in obj) {
		const value = obj[prop],
			type = typeof value;
		if (value != null && obj.hasOwnProperty(prop)) {
			if (type === 'object') {
				objTrim[prop] = obj[prop];
				Trim(obj[prop]);
			} else {
				if (type === 'string') obj[prop] = obj[prop].trim();
				objTrim[prop] = obj[prop];
			}
		}
	}
	return objTrim;
};

export const DEFAULT_LANGUAGE = AVAILABLE_LANGUAGES[0];
