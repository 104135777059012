import { Reducer } from 'redux';
import * as types from './types';

import { initialStore } from '../initial-store';
import { SALES_SCHEMA } from '../../models/initial-store';

export const sales: Reducer<SALES_SCHEMA> = (state = initialStore.sales, { payload, type }) => {
	switch (type) {
		case types.GET_ADVERTS: {
			return { ...state, isFetching: true };
		}
		case types.START_BULL: {
			return { ...state, name: payload };
		}
		case types.GET_ADVERTS_SUCCESS: {
			const { count, rows } = payload;
			return { ...state, count, advertsList: rows, isFetching: false };
		}
		case types.GET_ADVERTS_FAIL: {
			return { ...state, isFetching: false };
		}

		case types.ADD_COMMENT: {
			return { ...state, isFetching: true };
		}
		case types.ADD_COMMENT_SUCCESS: {
			const { id } = payload;
			return {
				...state,
				isFetching: false,
				advertsList: state.advertsList.map(item => (item.id === id ? payload : item)),
			};
		}
		case types.ADD_COMMENT_FAIL: {
			return { ...state, isFetching: false };
		}

		default:
			return state;
	}
};
