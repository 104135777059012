import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

// CONSTANTS
import { URLS } from '../../constants/URLS';
import { ADMIN_ROLE, AVAILABLE_ROLES, SELLER_ROLE } from '../../constants';

// COMPONENTS
import { CustomIcon, CustomHeader } from '..';

// UTILS
import { filterProtected, AbstractRoute } from '../../utils/filter-protected';

import { STORE } from '../../models/initial-store';
import { globalCollapsed } from '../../store/global/actions';
import { globalCollapsedType } from '../../models/global';

import { GeneralAppContext } from '../../context';

import './style.scss';

import logo from '../../assets/twsgo_logo_white.png';

type LAYOUT_PROPS = {
	children: React.ReactNode;
	globalCollapsed: globalCollapsedType;
	collapsed: boolean;
};

type LAYOUT_STATE = {};

interface OwnProps {}

interface StoreProps {
	collapsed: boolean;
}

interface DispatchProps {
	globalCollapsed: globalCollapsedType;
}

const { Sider, Content, Header } = Layout;
class CustomLayout extends Component<LAYOUT_PROPS & RouteComponentProps, LAYOUT_STATE> {
	static contextType = GeneralAppContext;
	context!: React.ContextType<typeof GeneralAppContext>;

	toggle = () => {
		const { globalCollapsed, collapsed } = this.props;
		globalCollapsed({ collapsed: !collapsed });
	};

	changeLocation = ({ path, external }: { path: string; external: boolean }) => {
		const { history } = this.props;

		if (external) return window.open(path, '_blank');

		history.push(path);
	};

	render() {
		const { children, location, collapsed } = this.props;
		const { json } = this.context;
		const { HEADER } = json;

		const MAIN_ROUTES = [
			{
				title: HEADER.dashboardLinkTitle,
				path: URLS.dashboard,
				hasAccess: AVAILABLE_ROLES,
				icon: 'dashboard',
				external: false,
			},
			{
				title: HEADER.usersLinkTitle,
				path: URLS.users,
				hasAccess: [ADMIN_ROLE],
				icon: 'user',
				external: false,
			},
			{
				title: HEADER.membersLinkTitle,
				path: URLS.members,
				hasAccess: [ADMIN_ROLE],
				icon: 'usergroup-add',
				external: false,
			},
			{
				title: HEADER.saleLinkTitle,
				path: URLS.sale,
				hasAccess: [SELLER_ROLE, ADMIN_ROLE],
				icon: 'bar-chart',
				external: false,
			},
			{
				title: HEADER.bullBoardLinkTitle,
				path: URLS.bullBoard,
				hasAccess: [ADMIN_ROLE],
				icon: 'container',
				external: false,
			},
			{
				title: HEADER.portfolio,
				path: URLS.portfolio,
				hasAccess: [ADMIN_ROLE],
				icon: 'form',
				external: false,
			},
			{
				title: HEADER.htmlEditor,
				path: URLS.htmlEditor,
				hasAccess: [ADMIN_ROLE],
				icon: 'form',
				external: false,
			},
		];

		return (
			<Layout className="layout">
				<Sider trigger={null} collapsible collapsed={collapsed} width={250}>
					<Menu mode="inline" className="layout__menu" selectedKeys={[location.pathname]}>
						<div className="logo" onClick={this.changeLocation.bind(null, { path: URLS.landing, external: false })}>
							<img src={logo} alt="" />
						</div>
						{filterProtected({
							routesSchema: MAIN_ROUTES,
							renderCallback: ({ title, path, icon, external }: AbstractRoute) => (
								<Menu.Item
									key={path}
									className="layout__menu-item"
									onClick={this.changeLocation.bind(null, { path, external })}
								>
									{icon && !collapsed && (
										<div className="layout__icon-box">
											<CustomIcon type={icon} />
										</div>
									)}
									{icon && collapsed && <CustomIcon type={icon} />}
									<span>{title}</span>
								</Menu.Item>
							),
						})}
					</Menu>
				</Sider>
				<Layout>
					<Header className="layout__header">
						<div>
							<CustomIcon type={'menu'} onClick={this.toggle} />
						</div>
						<CustomHeader {...this.props} />
					</Header>
					<Content>{children}</Content>
				</Layout>
			</Layout>
		);
	}
}

const mapStateToProps = (state: STORE) => ({
	collapsed: state.global.collapsed,
});

export default connect<StoreProps, DispatchProps, OwnProps, STORE>(mapStateToProps, { globalCollapsed })(CustomLayout);
