import { Reducer } from 'redux';
import * as types from './types';

import { initialStore } from '../initial-store';
import { PASSWORD_RESET_SCHEMA } from '../../models/initial-store';

export const passwordReset: Reducer<PASSWORD_RESET_SCHEMA> = (
	state = initialStore.passwordReset,
	{ payload, type },
) => {
	switch (type) {
		case types.PASSWORD_RESET: {
			return { ...state, isFetching: true, error: null };
		}
		case types.PASSWORD_RESET_SUCCESS: {
			return { ...state, isFetching: false, error: null };
		}
		case types.PASSWORD_RESET_FAIL: {
			return { ...state, isFetching: false, error: payload };
		}

		default:
			return state;
	}
};
