import { AxiosError } from 'axios';
import * as ACTION_TYPES from './types';

import {
	GetPortfolioActionCreator,
	GetPortfolioSuccessActionCreator,
	GetPortfolioFailureActionCreator,
	DeletePortfolioItemActionCreator,
	DeletePortfolioItemSuccessActionCreator,
	DeletePortfolioItemFailureActionCreator,
	PAGINATION,
	PortfolioItem,
} from '../../models/portfolio';

// CREATE
export const createPortfolioItem = (payload: PortfolioItem) => ({
	type: ACTION_TYPES.CREATE_PORTFOLIO_WORK,
	payload,
});

export const createPortfolioItemSuccess = (payload: PortfolioItem) => ({
	type: ACTION_TYPES.CREATE_PORTFOLIO_WORK_SUCCESS,
	payload,
});

export const createPortfolioItemFailure = (payload: AxiosError) => ({
	type: ACTION_TYPES.CREATE_PORTFOLIO_WORK_FAILURE,
	payload,
});

// READ
export const getPortfolio: GetPortfolioActionCreator = (payload: PAGINATION) => ({
	type: ACTION_TYPES.GET_PORTFOLIO,
	payload,
});
export const getPortfolioSuccess: GetPortfolioSuccessActionCreator = payload => ({
	type: ACTION_TYPES.GET_PORTFOLIO_SUCCESS,
	payload,
});
export const getPortfolioFailure: GetPortfolioFailureActionCreator = payload => ({
	type: ACTION_TYPES.GET_PORTFOLIO_FAILURE,
	payload,
});

// UPDATE
export const updatePortfolioItem = (payload: PortfolioItem) => ({
	type: ACTION_TYPES.UPDATE_PORTFOLIO_WORK,
	payload,
});

export const updatePortfolioItemSuccess = (payload: PortfolioItem) => ({
	type: ACTION_TYPES.UPDATE_PORTFOLIO_WORK_SUCCESS,
	payload,
});

export const updatePortfolioItemFailure = (payload: AxiosError) => ({
	type: ACTION_TYPES.UPDATE_PORTFOLIO_WORK_FAILURE,
	payload,
});

// DELETE
export const deletePortfolioItem: DeletePortfolioItemActionCreator = payload => ({
	type: ACTION_TYPES.DELETE_PORTFOLIO_WORK,
	payload,
});
export const deletePortfolioItemSuccess: DeletePortfolioItemSuccessActionCreator = payload => ({
	type: ACTION_TYPES.DELETE_PORTFOLIO_WORK_SUCCESS,
	payload,
});
export const deletePortfolioItemFailure: DeletePortfolioItemFailureActionCreator = payload => ({
	type: ACTION_TYPES.DELETE_PORTFOLIO_WORK_FAILURE,
	payload,
});

// GET BY ID
export const getPortfolioById = (payload: string) => ({
	type: ACTION_TYPES.GET_PORTFOLIO_BY_ID,
	payload,
});

export const getPortfolioByIdSuccess = (payload: PortfolioItem) => ({
	type: ACTION_TYPES.GET_PORTFOLIO_BY_ID_SUCCESS,
	payload,
});

export const getPortfolioByIdFailure = (payload: AxiosError) => ({
	type: ACTION_TYPES.GET_PORTFOLIO_BY_ID_FAILURE,
	payload,
});

// other
export const resetPortfolio = () => ({
	type: ACTION_TYPES.RESET_PORTFOLIO,
});
