import { call, takeEvery, put } from 'redux-saga/effects';
import { API_URLS } from '../../constants/API_URLS';

// ACTION TYPES
import { SIGN_IN_REQUEST, SIGN_IN_OAUTH } from '../../store/sign-in-page/types';
import { PASSWORD_RESET } from '../../store/password-reset/types';
import { PASSWORD_RESTORE, VALIDATE_RESTORE_TOKEN } from '../../store/password-restore/types';

// ACTIONS
import { signInRequestFail, signInRequestSuccess } from '../../store/sign-in-page/action';
import { getCurrentUserSuccess } from '../../store/users/action';
import { passwordResetFail, passwordResetSuccess } from '../../store/password-reset/actions';
import {
	passwordRestoreSuccess,
	passwordRestoreFail,
	validateTokenSuccess,
	validateTokenFail,
} from '../../store/password-restore/actions';

// MODELS
import {
	SignInRequestResponse,
	SignInRequestAction,
	PasswordResetAction,
	PasswordResetSuccessResponse,
	PasswordRestoreSuccessResponse,
	PasswordRestoreAction,
	ValidateTokenAction,
	ValidateTokenSuccessResponse,
	SignInOAuthAction,
} from '../../models/auth';

// UTILS
import { http } from '../../axios';
import { setToken } from '../../utils/localstage-actions';
import { openAxiosSuccessNotification, openNotificationSuccess } from '../../utils/show-notification';

function* logIn({ payload }: SignInRequestAction) {
	try {
		const encodedData = yield window.btoa(`${payload.email}:${payload.password}`);

		const response: SignInRequestResponse = yield call(http, {
			url: API_URLS.auth,
			method: 'POST',
			data: { access_token: process.env.REACT_APP_MASTER_KEY },
			headers: {
				Authorization: `Basic ${encodedData}`,
			},
		});

		yield setToken(response.data.token);

		yield put(signInRequestSuccess());
		yield put(getCurrentUserSuccess(response.data.user));
		yield openNotificationSuccess({ message: `${response.data.message} ${response.data.user.name}` });
	} catch (error) {
		yield put(signInRequestFail(error.response));
	}
}

function* resetPasswordWatcher({ payload }: PasswordResetAction) {
	try {
		const link = process.env.REACT_APP_FRONTEND_URL;
		const response: PasswordResetSuccessResponse = yield call(http, {
			url: API_URLS.resetPass,
			method: 'POST',
			data: {
				email: payload.email,
				link,
			},
			headers: {
				Authorization: `Bearer ${process.env.REACT_APP_MASTER_KEY}`,
			},
		});
		yield call(openAxiosSuccessNotification, response);

		yield put(passwordResetSuccess());
	} catch (err) {
		yield put(passwordResetFail(err));
	}
}

function* restorePasswordWatcher({ payload }: PasswordRestoreAction) {
	try {
		const { password, token } = payload;
		const response: PasswordRestoreSuccessResponse = yield call(http, {
			url: `${API_URLS.resetPass}/${token}`,
			method: 'PUT',
			data: { password },
		});
		yield put(passwordRestoreSuccess(response));
		yield call(openNotificationSuccess, {
			message: payload.messagePopUp,
			description: payload.messagePopUpDes,
		});
	} catch (err) {
		yield put(passwordRestoreFail(err));
	}
}

function* validateTokenWatcher({ payload }: ValidateTokenAction) {
	try {
		const { token } = payload;
		const response: ValidateTokenSuccessResponse = yield call(http, {
			url: `${API_URLS.resetPass}/${token}`,
			method: 'get',
		});
		yield put(validateTokenSuccess(response));
	} catch (err) {
		yield put(validateTokenFail(err));
	}
}

function* loginOAuth({ payload: { code, provider } }: SignInOAuthAction) {
	try {
		const response: SignInRequestResponse = yield call(http, {
			url: API_URLS.loginOAuth[provider],
			method: 'POST',
			data: { code },
		});

		yield setToken(response.data.token);

		yield put(signInRequestSuccess());
		yield put(getCurrentUserSuccess(response.data.user));
		yield openNotificationSuccess({ message: `Welcome back ${response.data.user.name}` });
	} catch (error) {
		yield put(signInRequestFail(error.response));
	}
}

function* auth() {
	yield takeEvery(SIGN_IN_REQUEST, logIn);
	yield takeEvery(PASSWORD_RESET, resetPasswordWatcher);
	yield takeEvery(PASSWORD_RESTORE, restorePasswordWatcher);
	yield takeEvery(VALIDATE_RESTORE_TOKEN, validateTokenWatcher);
	yield takeEvery(SIGN_IN_OAUTH, loginOAuth);
}

export default auth;
