import { Reducer } from 'redux';
import * as ACTION_TYPES from './types';

import { initialStore } from '../initial-store';
import { PORTFOLIO_INTERFACE } from '../../models/initial-store';
import { SEARCH_PORTFOLIO_FORM } from '../../constants';
import { setLink } from '../../utils/check-absolute-path';
import { PortfolioItem } from '../../models/portfolio';

export const portfolioReducer: Reducer<PORTFOLIO_INTERFACE> = (
	state = initialStore.portfolio,
	{ payload, type, meta },
) => {
	switch (type) {
		case ACTION_TYPES.GET_PORTFOLIO_BY_ID:
		case ACTION_TYPES.CREATE_PORTFOLIO_WORK:
		case ACTION_TYPES.GET_PORTFOLIO:
		case ACTION_TYPES.UPDATE_PORTFOLIO_WORK:
		case ACTION_TYPES.DELETE_PORTFOLIO_WORK: {
			return { ...state, isFetching: true, error: null };
		}

		case ACTION_TYPES.GET_PORTFOLIO_BY_ID_FAILURE:
		case ACTION_TYPES.CREATE_PORTFOLIO_WORK_FAILURE:
		case ACTION_TYPES.GET_PORTFOLIO_FAILURE:
		case ACTION_TYPES.UPDATE_PORTFOLIO_WORK_FAILURE:
		case ACTION_TYPES.DELETE_PORTFOLIO_WORK_FAILURE: {
			return { ...state, isFetching: false, error: payload };
		}

		case ACTION_TYPES.CREATE_PORTFOLIO_WORK_SUCCESS: {
			return { ...state, currentPortfolio: payload, isFetching: false, error: null };
		}

		case ACTION_TYPES.GET_PORTFOLIO_SUCCESS: {
			const { rows, count } = payload;
			const portfolioItems = rows.map((el: PortfolioItem) => ({
				...el,
				image: setLink(el.image),
				modalImages: el.modalImages && el.modalImages.map(setLink),
			}));
			return { ...state, isFetching: false, error: null, portfolioItems, count };
		}

		case ACTION_TYPES.UPDATE_PORTFOLIO_WORK_SUCCESS: {
			return { ...state, currentPortfolio: payload, isFetching: false, error: null };
		}

		case ACTION_TYPES.DELETE_PORTFOLIO_WORK_SUCCESS: {
			return {
				...state,
				isFetching: false,
				error: null,
				portfolioItems: state.portfolioItems.filter(work => work.id !== payload.id),
			};
		}

		case ACTION_TYPES.GET_PORTFOLIO_BY_ID_SUCCESS: {
			const currentPortfolio = {
				...payload,
				image: setLink(payload.image),
				modalImages: payload.modalImages.map(setLink),
			};
			return { ...state, isFetching: false, currentPortfolio };
		}

		case ACTION_TYPES.RESET_PORTFOLIO: {
			return { ...state, currentPortfolio: initialStore.portfolio.currentPortfolio };
		}

		case '@@redux-form/CHANGE': {
			if (meta.form === SEARCH_PORTFOLIO_FORM) {
				return { ...state, searchQueryString: payload };
			}
			return state;
		}

		case '@@redux-form/RESET': {
			if (meta.form === SEARCH_PORTFOLIO_FORM) {
				return { ...state, searchQueryString: '' };
			}
			return state;
		}

		default:
			return state;
	}
};
