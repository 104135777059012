import { checkJWT } from './check-jwt';

export type AbstractRoute = {
	path: string;
	title: string;
	hasAccess: string[];
	icon?: string;
	external: boolean;
};

type CONFIG = {
	routesSchema: AbstractRoute[];
	renderCallback: (routeSchema: AbstractRoute) => JSX.Element;
};

export function filterProtected({ routesSchema, renderCallback }: CONFIG) {
	const [, role] = checkJWT();
	return routesSchema
		.filter(el => el.hasAccess.length === 0 || el.hasAccess.some(el => el === role))
		.map(routeSchema => renderCallback(routeSchema));
}
