import React from 'react';
import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';

import './style.scss';

interface CARD extends CardProps {
	children: React.ReactNode;
	className?: string;
}

export const CustomCard = ({ children, className = '', ...rest }: CARD) => {
	return (
		<Card className={`custom-card ${className}`} {...rest}>
			{children}
		</Card>
	);
};
