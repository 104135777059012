import * as types from './types';
import { AxiosError } from 'axios';

// MODELS
import { SignInOAuthPayload, SignInRequestPayload } from '../../models/auth';

export const signInRequest = (payload: SignInRequestPayload) => ({
	type: types.SIGN_IN_REQUEST,
	payload,
});

export const signInRequestSuccess = () => ({
	type: types.SIGN_IN_REQUEST_SUCCESS,
});

export const signInRequestFail = (payload: AxiosError) => ({
	type: types.SIGN_IN_REQUEST_FAIL,
	payload,
});

export const resetSignInReducer = () => ({
	type: types.SIGN_IN_RESET_REDUCER,
});

export const signInOAuth = (payload: SignInOAuthPayload) => ({
	type: types.SIGN_IN_OAUTH,
	payload,
});
