import { Reducer } from 'redux';
import * as types from './types';

import { initialStore } from '../initial-store';
import { SIGN_IN_PAGE_SCHEMA } from '../../models/initial-store';

export const signInPage: Reducer<SIGN_IN_PAGE_SCHEMA> = (state = initialStore.signInPage, { payload, type }) => {
	switch (type) {
		case types.SIGN_IN_REQUEST: {
			return { ...state, isFetching: true, error: null };
		}
		case types.SIGN_IN_REQUEST_SUCCESS: {
			return { ...state, isFetching: false, error: null };
		}
		case types.SIGN_IN_REQUEST_FAIL: {
			return { ...state, isFetching: false, error: payload };
		}
		case types.SIGN_IN_RESET_REDUCER: {
			return initialStore.signInPage;
		}

		default:
			return state;
	}
};
