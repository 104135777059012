export const GET_LIST_OF_MEMBERS = 'member/GET_LIST_OF_MEMBERS';
export const GET_LIST_OF_MEMBERS_SUCCESS = 'member/GET_LIST_OF_MEMBERS_SUCCESS';
export const GET_LIST_OF_MEMBERS_FAIL = 'member/GET_LIST_OF_MEMBERS_FAIL';

export const CREATE_MEMBER = 'member/CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'member/CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAIL = 'member/CREATE_MEMBER_FAIL';

export const DELETE_MEMBER = 'member/DELETE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'member/DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAIL = 'member/DELETE_MEMBER_FAIL';

export const UPDATE_MEMBER = 'member/UPDATE_MEMBER';
export const UPDATE_MEMBER_SUCCESS = 'member/UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAIL = 'member/UPDATE_MEMBER_FAIL';

export const SELECT_MEMBER = 'member/SELCET_MEMBER';

export const UPDATE_MEMBER_ORDER = 'member/UPDATE_MEMBER_ORDER';
export const UPDATE_MEMBER_ORDER_SUCCESS = 'member/UPDATE_MEMBER_ORDER_SUCCESS';
export const UPDATE_MEMBER_ORDER_FAIL = 'member/UPDATE_MEMBER_ORDER_FAIL';
