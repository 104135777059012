import { combineReducers, Reducer, AnyAction } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { GLOBAL_STORE_RESET } from './global/types';
import { initialStore } from './initial-store';
import { STORE } from '../models/initial-store';

// reducers
import { global } from './global/reducer';
import { signInPage } from './sign-in-page/reducer';
import { users } from './users/reducer';
import { members } from './members/reducer';
import { passwordReset } from './password-reset/reducer';
import { portfolioReducer } from './portfolio/reducer';
import { passwordRestore } from './password-restore/reducer';
import { sales } from './sales/reducer';
import { servicesReducer } from './services/reducer';

const appReducer = combineReducers({
	form: formReducer,
	global,
	signInPage,
	users,
	members,
	passwordReset,
	passwordRestore,
	sales,
	portfolio: portfolioReducer,
	services: servicesReducer,
});

export const rootReducer: Reducer<STORE, AnyAction> = (state, action): STORE => {
	if (action.type === GLOBAL_STORE_RESET) {
		// way to set global action
		return initialStore;
	}

	return appReducer(state, action);
};
