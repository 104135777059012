// PAGINATION
export const USER_LIST_PAGINATION = {
	page: 1,
	limit: 10,
};
export const DEFAULT_PAGE_LIMIT_MEMBERS_LIST = 10;
export const DEFAULT_PAGE_LIMIT_PORTFOLIO_LIST = 10;
export const DEFAULT_PAGE_LIMIT_ADVERTS_LIST = 30;

export const DEFAULT_SORT_ADVERTS_LIST = '-date';
export const DEFAULT_MEMBERS_SORT_VALUE = 'order';

// FORMS
export const SIGN_IN_FORM_NAME = 'sign-in-page-form';
export const PASSWORD_RESET_FORM_NAME = 'password-reset-form';
export const PASSWORD_RESTORE_FORM_NAME = 'password-restore-form';
export const CREATE_USER_FORM = 'create-user';
export const SEARCH_USER_FORM = 'search-user';
export const CREATE_MEMBER_FORM = 'create-member';
export const SEARCH_MEMBER_FORM = 'search-member';
export const SEARCH_PORTFOLIO_FORM = 'search-portfolio';
export const GENERAL_USER_INFO_FORM = 'general-user-info-form';
export const ADD_COMMENT_FORM = 'add-comment-form';
export const PORTFOLIO_FORM = 'portfolio-form';
export const CHANGE_USER_AVATAR_FORM = 'changeUserAvatarForm';

export const ADMIN_ROLE = 'admin';
export const USER_ROLE = 'user';
export const SELLER_ROLE = 'seller';

export const AVAILABLE_ROLES = [ADMIN_ROLE, USER_ROLE, SELLER_ROLE];

export const SALE_CATEGORY = [{ value: 'sof', title: 'Software/QA/DBA' }];

export const AVAILABLE_PORTFOLIO_TYPES = [
	{ title: 'Modal', value: 'modal' },
	{ title: 'Link', value: 'link' },
];

export const RELATIVE_URL_PREFIX = '{BACKEND_URL}';

export const CRANGSLIST = 'crangslist';
