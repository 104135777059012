import React from 'react';

import { I18n } from '../models/i18n';
import { socket } from '../utils/socketConnect';

import { DEFAULT_THEME } from '../utils/css-property';
import { DEFAULT_LANGUAGE } from '../i18n';

export type GENERAL_APP_CONTEXT = {
	innerWidth: number;
	setLanguage: (payload: string) => void;
	json: I18n;
	theme: boolean;
	setTheme: (payload: boolean) => void;
	socket: SocketIOClient.Socket;
};

export const GeneralAppContext = React.createContext<GENERAL_APP_CONTEXT>({
	innerWidth: window.innerWidth,
	setLanguage: () => {},
	json: DEFAULT_LANGUAGE.json,
	theme: DEFAULT_THEME,
	setTheme: () => {},
	socket: socket,
});
