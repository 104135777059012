import React from 'react';
import { Icon } from 'antd';

import './style.scss';

type IconTypes = {
	type: string;
	onClick?: () => void;
	className?: string;
	theme?: 'filled' | 'outlined' | 'twoTone';
	twoToneColor?: string;
};
// You can find all icons on https://ant.design/components/icon/
export const CustomIcon = ({ type, onClick, className = '', theme = 'outlined', ...rest }: IconTypes) => {
	return <Icon className={`icon ${className}`} type={type} onClick={onClick} theme={theme} {...rest} />;
};
